form.contact-form input,
textarea.form-control {
  border: none;
  border-radius: 0;
}

form.contact-form input.form-control:focus,
textarea.form-control:focus {
  outline: none !important;
  border: none;
  box-shadow: none;
}

form.contact-form button.btn.btn-primary {
  color: #0062cc;
  background: #ffffff;
  border: none;
}
