.card {
  border: none;
  background: transparent;
}

.card.project-card {
  padding-top: 35px;
}

.project-container {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.project-container .project-image {
  width: 100%;
  height: 100%;
}

.project-container .project-image img {
  width: 100%;
  height: 100%;
}

.item {
  background: #fff;
  margin: 0 0 2.5rem 0;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -moz-page-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  display: block;
  width: 100%;
  cursor: pointer;
}

/* Project Item Refactor - 082819 */
.project-item-wrapper {
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  margin: 0 0 1.5rem 0;
  transition: box-shadow 0.2s ease;
  position: relative;
  overflow: hidden;
}

.project-item-wrapper:hover {
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.4),
    0 15px 40px rgba(166, 173, 201, 0.6);
}

/* Overlay behavior */
.project-item-wrapper:hover .item-image-overlay .item-image-overlay-content {
  height: 100px;
  opacity: 1;
}

.project-item-wrapper:hover .item-image-wrapper img {
  opacity: 0.8;
}

.project-item-wrapper:hover .item-image-overlay {
  transform: translateY(0);
}

.item-image-wrapper {
  position: relative;
  overflow-y: hidden;
  background: #000000;
  transition: all 0.2s;
}

.item-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.item-image-overlay > .item-image-overlay-content {
  background: #fff;
  width: 100%;
  height: 0;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.item-image-wrapper img {
  width: 100%;
  transition: opacity 0.2s;
}

.project-item-bottom {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap;
}

.project-tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.project-tag {
  margin: 0 3px 5px 3px;
  background: #e8e8e8;
  border-radius: 15px;
  padding: 2px 10px;
  font-size: 11px;
  text-align: center;
  color: #616161;
}

.item-links button {
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1px;
  border: none;
  padding: 7px 5px;
}

.item-links button.details {
  background: none;
  color: #0196fe;
  transition: all;
  transition-duration: 0.15s;
}

.item-links button.details:hover {
  color: #0174c4;
}

.item-links button.view-live {
  background: #0196fe;
  color: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.22), 0 3px 6px rgba(0, 0, 0, 0.14);
  transition: all;
  transition-duration: 0.15s;
}

.item-links button.view-live:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.22), 0 4px 8px rgba(0, 0, 0, 0.24);
}
