section#about {
  padding: 35px 0 60px 0;
}

section#about h3 {
  color: #494949;
}

section#about .about-content,
.key-skills-content {
  padding: 20px 32px;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  text-align: left;
  margin-bottom: 40px;
  background: #ffffff;
}

section#about .about-content p {
  color: #5f5f5f;
  font-weight: 100;
  line-height: 1.8em;
  text-align: left;
}

section#about .key-skills-content ul {
  list-style: none;
  padding-left: 0;
  text-align: left;
  margin-top: 20px;
}

section#about .key-skills-content ul li {
  text-align: left;
  background: linear-gradient(45deg, #2a6bb1, #2280e4);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 30px;
  align-items: center;
  margin-bottom: 25px;
  font-size: 0.85rem;
}

section#about .key-skills-content ul li span.key-skill-icon {
  /* background: #a8c4e2; */
  padding: 5px 10px;
  border-radius: 25px;
  color: #ffffff;
  min-width: 66px;
}

section#about .cert-row {
  display: flex;
  margin: 30px 0;
  flex-wrap: wrap;
}

section#about .cert-row .cert-img {
  max-width: 250px;
  margin-bottom: 10px;
}

section#about .cert-row .cert-img img {
  width: 180px;
}

section#about .cert-row .cert-name {
  display: flex;
  align-items: center;
}

section#about .cert-row .cert-name p {
  color: #5f5f5f;
  font-weight: 100;
  text-align: left;
  margin: 0;
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  section#about .cert-row .cert-name p {
    padding-left: 0;
  }
}
