section#hero {
  padding: 200px 0 200px 0;
  background: #00c6ff; /* fallback for old browsers */
  background: linear-gradient(
    to top,
    #0072ff,
    #00c6ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  transform: skew(0, -3deg);

  margin: 8rem 0 5rem 0;
}

section#hero .hero-content {
  transform: skew(0, 3deg);
}

section#hero h1 {
  color: #ffffff;
}

section#hero .hero-buttons button {
  min-width: 120px;
  letter-spacing: 0.085em;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition-property: all;
  transition-property: transform;
  transition-duration: 0.15s;
}

section#hero .hero-buttons button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.2);
}

section#hero .hero-buttons button.btn-green {
  background: #3ecf8e;
  color: #ffffff;
  border: none;
}

section#hero .hero-buttons button.btn-white {
  background: #ffffff;
  color: #167dfb;
  border: none;
}
