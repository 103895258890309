section#footer {
  margin-top: 15vh;
  background: #0062cc;
  color: #ffffff;
  padding: 2em;
}

section#footer .contact-info-wrapper {
  margin-bottom: 5vh;
}

section#footer .contact-info-wrapper h6 {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

section#footer .contact-info-wrapper span.icon {
  margin: 0 13px 0 0;
}

section#footer .contact-info-wrapper p {
  color: #ffffff;
  text-align: left;
  font-size: 14px;
  margin-bottom: 5px;
}

section#footer .contact-info-wrapper p a {
  color: #ffffff;
}

section#footer .row .col.copy p {
  text-align: center;
  color: #ffffff;
  font-weight: 100;
  font-size: 0.8rem;
  margin: 10vh 0 3vh 0;
}

section#footer .footer-form-area h6 {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}
