#project-detail {
  background: #efefef;
  transform: skew(0, -3deg);
  padding: 16vh 0 30vh 0;
  margin-top: 6rem;
}

.project-detail-wrapper {
  transform: skew(0, 3deg);
}

.project-data {
  padding: 20px;
  background: #ffffff;
  margin-bottom: 20px;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  text-align: left;
}

#project-detail .project-data h3.project-title,
.project-description-long {
  text-align: left;
  color: #5f5f5f;
  line-height: 1.8em;
  font-weight: 100;
}

.back-button {
  background: none;
  color: #0196fe;
  border-radius: 0.25rem;
  padding: 0;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  margin: 0;
  font-size: 0.8rem;
  min-width: 100px;
}

.back-button:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  #project-detail {
    padding-top: 7vh;
  }
}
