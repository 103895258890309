section#skills {
  background: #1fa2ff;
  transform: skew(0, -3deg);
  margin-top: 20px;
  margin-bottom: 80px;
  padding: 60px 0 100px 0;
}

section#skills .skills-wrapper {
  transform: skew(0, 3deg);
  margin: auto;
}

section#skills .skills-title h3 {
  text-align: center;
  color: #ffffff;
  font-weight: 300;
}

section#skills .skills-column-content {
  text-align: center;
  color: #494949;
}

section#skills .skills-column-content figure {
  text-align: center;
}

section#skills .skills-column-content figure i {
  color: #0196fe;
}

section#skills .skills-column-content h5 {
  margin: 20px 0;
}

section#skills .skills-column-content .lead-in {
  font-weight: 100;
  font-size: 17px;
  text-align: center;
}

section#skills .skills-column-content .section-header {
  color: #0196fe;
  margin-bottom: 6px;
  text-align: center;
}

section#skills .skills-column-content .section-list {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
}

section#skills .skills-column-content .section-list li {
  font-weight: 100;
}

section#skills .frameworks-title {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
}

section#skills .frameworks-title:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: #0196fe;
  margin-top: -5px;
}

section#skills .frameworks {
  transition: max-height 0.3s ease-in-out;
  max-height: 0;
  overflow-y: hidden;
}

section#skills .frameworks.expanded {
  max-height: 300px;
}

section#skills
  .skills-row
  .skills-column:first-of-type
  .skills-column-content:first-of-type {
  border-right: solid 1px #d4d9e5;
}

section#skills
  .skills-row
  .skills-column:nth-of-type(2)
  .skills-column-content:first-of-type {
  border-right: solid 1px #d4d9e5;
}

section#skills .skills-column-content {
  padding: 35px 25px;
  background: #fbfbfb;
  height: 100%;
}

@media screen and (max-width: 768px) {
  section#skills
    .skills-row
    .skills-column:first-of-type
    .skills-column-content:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-right: none;
  }

  section#skills
    .skills-row
    .skills-column:nth-of-type(2)
    .skills-column-content:first-of-type {
    border-right: none;
  }

  section#skills
    .skills-row
    .skills-column:last-of-type
    .skills-column-content:last-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  section#skills .skills-row .skills-column:nth-of-type(1),
  .skills-column:nth-of-type(2) {
    border-bottom: solid 1px #d4d9e5;
  }
}
