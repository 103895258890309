.showcase-container {
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  background: #fff;
  padding: 25px;
}

.project-showcase {
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

.project-showcase .web-project-showcase-img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .project-showcase {
    border: solid 1px #ccc;
  }
}

.showcaseFrame {
  border: none;
  width: 100%;
  height: 100vh;
  transition: width 0.25s;
}

.showcaseFrame.mobileView {
  width: 60% !important;
  /* border: solid 1px #dcdcdc; */
}

.showcase-view-options {
  /* background: #fff; */
  margin-bottom: 20px;
}

.change-view {
  background: #0196fe;
  color: #fff;
  border-radius: 0.25rem;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  margin: 0 15px;
  font-size: 0.8rem;
}

.change-view:first-of-type {
  margin-left: 0;
}

.change-view:focus {
  outline: none;
}
