section#projects button.filter-button {
  background: #ffffff;
  color: #0196fe;
  border-radius: 0.25rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  -webkit-transition: all;
  transition: all;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  min-width: 215px;
  margin: 0 35px 20px 35px;
}

section#projects button.filter-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.4);
}

section#projects button.filter-button.active {
  background: #0196fe;
  color: #ffffff;
}
