@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200,400,700);
.lds-dual-ring {
  display: block;
  width: 64px;
  height: 64px;
  margin: auto;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #c5c5c5;
  border-color: #c5c5c5 transparent #c5c5c5 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.showcase-container {
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  background: #fff;
  padding: 25px;
}

.project-showcase {
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

.project-showcase .web-project-showcase-img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .project-showcase {
    border: solid 1px #ccc;
  }
}

.showcaseFrame {
  border: none;
  width: 100%;
  height: 100vh;
  -webkit-transition: width 0.25s;
  transition: width 0.25s;
}

.showcaseFrame.mobileView {
  width: 60% !important;
  /* border: solid 1px #dcdcdc; */
}

.showcase-view-options {
  /* background: #fff; */
  margin-bottom: 20px;
}

.change-view {
  background: #0196fe;
  color: #fff;
  border-radius: 0.25rem;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  margin: 0 15px;
  font-size: 0.8rem;
}

.change-view:first-of-type {
  margin-left: 0;
}

.change-view:focus {
  outline: none;
}

#project-detail {
  background: #efefef;
  -webkit-transform: skew(0, -3deg);
          transform: skew(0, -3deg);
  padding: 16vh 0 30vh 0;
  margin-top: 6rem;
}

.project-detail-wrapper {
  -webkit-transform: skew(0, 3deg);
          transform: skew(0, 3deg);
}

.project-data {
  padding: 20px;
  background: #ffffff;
  margin-bottom: 20px;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  text-align: left;
}

#project-detail .project-data h3.project-title,
.project-description-long {
  text-align: left;
  color: #5f5f5f;
  line-height: 1.8em;
  font-weight: 100;
}

.back-button {
  background: none;
  color: #0196fe;
  border-radius: 0.25rem;
  padding: 0;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  margin: 0;
  font-size: 0.8rem;
  min-width: 100px;
}

.back-button:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  #project-detail {
    padding-top: 7vh;
  }
}

.typedText {
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 200;
}

.typed-cursor {
  color: #ffffff;
}

section#hero {
  padding: 200px 0 200px 0;
  background: #00c6ff; /* fallback for old browsers */
  background: -webkit-gradient(
    linear,
    left bottom, left top,
    from(#0072ff),
    to(#00c6ff)
  );
  background: linear-gradient(
    to top,
    #0072ff,
    #00c6ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-transform: skew(0, -3deg);
          transform: skew(0, -3deg);

  margin: 8rem 0 5rem 0;
}

section#hero .hero-content {
  -webkit-transform: skew(0, 3deg);
          transform: skew(0, 3deg);
}

section#hero h1 {
  color: #ffffff;
}

section#hero .hero-buttons button {
  min-width: 120px;
  letter-spacing: 0.085em;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
}

section#hero .hero-buttons button:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.2);
}

section#hero .hero-buttons button.btn-green {
  background: #3ecf8e;
  color: #ffffff;
  border: none;
}

section#hero .hero-buttons button.btn-white {
  background: #ffffff;
  color: #167dfb;
  border: none;
}

section#about {
  padding: 35px 0 60px 0;
}

section#about h3 {
  color: #494949;
}

section#about .about-content,
.key-skills-content {
  padding: 20px 32px;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  text-align: left;
  margin-bottom: 40px;
  background: #ffffff;
}

section#about .about-content p {
  color: #5f5f5f;
  font-weight: 100;
  line-height: 1.8em;
  text-align: left;
}

section#about .key-skills-content ul {
  list-style: none;
  padding-left: 0;
  text-align: left;
  margin-top: 20px;
}

section#about .key-skills-content ul li {
  text-align: left;
  background: linear-gradient(45deg, #2a6bb1, #2280e4);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 30px;
  align-items: center;
  margin-bottom: 25px;
  font-size: 0.85rem;
}

section#about .key-skills-content ul li span.key-skill-icon {
  /* background: #a8c4e2; */
  padding: 5px 10px;
  border-radius: 25px;
  color: #ffffff;
  min-width: 66px;
}

section#about .cert-row {
  display: flex;
  margin: 30px 0;
  flex-wrap: wrap;
}

section#about .cert-row .cert-img {
  max-width: 250px;
  margin-bottom: 10px;
}

section#about .cert-row .cert-img img {
  width: 180px;
}

section#about .cert-row .cert-name {
  display: flex;
  align-items: center;
}

section#about .cert-row .cert-name p {
  color: #5f5f5f;
  font-weight: 100;
  text-align: left;
  margin: 0;
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  section#about .cert-row .cert-name p {
    padding-left: 0;
  }
}

section#skills {
  background: #1fa2ff;
  -webkit-transform: skew(0, -3deg);
          transform: skew(0, -3deg);
  margin-top: 20px;
  margin-bottom: 80px;
  padding: 60px 0 100px 0;
}

section#skills .skills-wrapper {
  -webkit-transform: skew(0, 3deg);
          transform: skew(0, 3deg);
  margin: auto;
}

section#skills .skills-title h3 {
  text-align: center;
  color: #ffffff;
  font-weight: 300;
}

section#skills .skills-column-content {
  text-align: center;
  color: #494949;
}

section#skills .skills-column-content figure {
  text-align: center;
}

section#skills .skills-column-content figure i {
  color: #0196fe;
}

section#skills .skills-column-content h5 {
  margin: 20px 0;
}

section#skills .skills-column-content .lead-in {
  font-weight: 100;
  font-size: 17px;
  text-align: center;
}

section#skills .skills-column-content .section-header {
  color: #0196fe;
  margin-bottom: 6px;
  text-align: center;
}

section#skills .skills-column-content .section-list {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
}

section#skills .skills-column-content .section-list li {
  font-weight: 100;
}

section#skills .frameworks-title {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
}

section#skills .frameworks-title:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: #0196fe;
  margin-top: -5px;
}

section#skills .frameworks {
  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;
  overflow-y: hidden;
}

section#skills .frameworks.expanded {
  max-height: 300px;
}

section#skills
  .skills-row
  .skills-column:first-of-type
  .skills-column-content:first-of-type {
  border-right: solid 1px #d4d9e5;
}

section#skills
  .skills-row
  .skills-column:nth-of-type(2)
  .skills-column-content:first-of-type {
  border-right: solid 1px #d4d9e5;
}

section#skills .skills-column-content {
  padding: 35px 25px;
  background: #fbfbfb;
  height: 100%;
}

@media screen and (max-width: 768px) {
  section#skills
    .skills-row
    .skills-column:first-of-type
    .skills-column-content:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-right: none;
  }

  section#skills
    .skills-row
    .skills-column:nth-of-type(2)
    .skills-column-content:first-of-type {
    border-right: none;
  }

  section#skills
    .skills-row
    .skills-column:last-of-type
    .skills-column-content:last-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  section#skills .skills-row .skills-column:nth-of-type(1),
  .skills-column:nth-of-type(2) {
    border-bottom: solid 1px #d4d9e5;
  }
}

.project-likes-wrapper {
  display: flex;
  justify-content: center;
  width: 150px;
  justify-content: space-between;
}

.project-likes-wrapper button {
  border-color: #0074ff;
  width: 50px;
}

.project-likes-wrapper button.liked {
  background: #0074ff;
  color: #ffffff;
}

.project-likes-wrapper .btn-outline-primary:focus {
  outline: none !important;
  box-shadow: none;
}

.card {
  border: none;
  background: transparent;
}

.card.project-card {
  padding-top: 35px;
}

.project-container {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.project-container .project-image {
  width: 100%;
  height: 100%;
}

.project-container .project-image img {
  width: 100%;
  height: 100%;
}

.item {
  background: #fff;
  margin: 0 0 2.5rem 0;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -moz-page-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  display: block;
  width: 100%;
  cursor: pointer;
}

/* Project Item Refactor - 082819 */
.project-item-wrapper {
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  margin: 0 0 1.5rem 0;
  -webkit-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
  position: relative;
  overflow: hidden;
}

.project-item-wrapper:hover {
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.4),
    0 15px 40px rgba(166, 173, 201, 0.6);
}

/* Overlay behavior */
.project-item-wrapper:hover .item-image-overlay .item-image-overlay-content {
  height: 100px;
  opacity: 1;
}

.project-item-wrapper:hover .item-image-wrapper img {
  opacity: 0.8;
}

.project-item-wrapper:hover .item-image-overlay {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.item-image-wrapper {
  position: relative;
  overflow-y: hidden;
  background: #000000;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.item-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.item-image-overlay > .item-image-overlay-content {
  background: #fff;
  width: 100%;
  height: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.item-image-wrapper img {
  width: 100%;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.project-item-bottom {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap;
}

.project-tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.project-tag {
  margin: 0 3px 5px 3px;
  background: #e8e8e8;
  border-radius: 15px;
  padding: 2px 10px;
  font-size: 11px;
  text-align: center;
  color: #616161;
}

.item-links button {
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1px;
  border: none;
  padding: 7px 5px;
}

.item-links button.details {
  background: none;
  color: #0196fe;
  -webkit-transition: all;
  transition: all;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
}

.item-links button.details:hover {
  color: #0174c4;
}

.item-links button.view-live {
  background: #0196fe;
  color: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.22), 0 3px 6px rgba(0, 0, 0, 0.14);
  -webkit-transition: all;
  transition: all;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
}

.item-links button.view-live:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.22), 0 4px 8px rgba(0, 0, 0, 0.24);
}

section#projects button.filter-button {
  background: #ffffff;
  color: #0196fe;
  border-radius: 0.25rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.2);
  -webkit-transition: all;
  transition: all;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  min-width: 215px;
  margin: 0 35px 20px 35px;
}

section#projects button.filter-button:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.25),
    0 15px 40px rgba(166, 173, 201, 0.4);
}

section#projects button.filter-button.active {
  background: #0196fe;
  color: #ffffff;
}

section#projects {
  padding: 30px 0 50px 0;
}

section#projects .projects-title {
  text-align: center;
  color: #494949;
  font-weight: 300;
}

section#projects .projects-subhead {
  text-align: center;
  color: #494949;
  font-weight: 300;
  margin-bottom: 30px;
}

@media screen and (max-width: 992px) {
  .card-columns {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
  }
}

@media screen and (min-width: 992px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
}

.navbar {
  padding-top: 1rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.navbar.scrolled {
  background: #fff;
  box-shadow: 0 7px 30px rgba(64, 84, 107, 0.3);
}

a.navbar-brand {
  color: rgba(0, 0, 0, 0.7) !important;
}

.nav-link {
  color: #5f5f5f;
  font-weight: 400;
}

.nav-link:hover {
  color: #0196fe;
  font-weight: 400;
}

.nav-toggle {
  color: rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 768px) {
  li.nav-item {
    text-align: left;
  }
}

@media screen and (max-width: 992px) {
  .navbar {
    background: #fbfbfb;
  }
}

form.contact-form input,
textarea.form-control {
  border: none;
  border-radius: 0;
}

form.contact-form input.form-control:focus,
textarea.form-control:focus {
  outline: none !important;
  border: none;
  box-shadow: none;
}

form.contact-form button.btn.btn-primary {
  color: #0062cc;
  background: #ffffff;
  border: none;
}

section#footer {
  margin-top: 15vh;
  background: #0062cc;
  color: #ffffff;
  padding: 2em;
}

section#footer .contact-info-wrapper {
  margin-bottom: 5vh;
}

section#footer .contact-info-wrapper h6 {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

section#footer .contact-info-wrapper span.icon {
  margin: 0 13px 0 0;
}

section#footer .contact-info-wrapper p {
  color: #ffffff;
  text-align: left;
  font-size: 14px;
  margin-bottom: 5px;
}

section#footer .contact-info-wrapper p a {
  color: #ffffff;
}

section#footer .row .col.copy p {
  text-align: center;
  color: #ffffff;
  font-weight: 100;
  font-size: 0.8rem;
  margin: 10vh 0 3vh 0;
}

section#footer .footer-form-area h6 {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.not-found-wrapper {
  margin: 10rem 0;
}

.not-found-wrapper a {
  color: #0162cc;
}

/*
----
App Level Styles
----
*/

/* Font Imports */

/* Font Stack */
html,
body {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  background-color: #fbfbfb;
}

