.navbar {
  padding-top: 1rem;
  transition: all 0.2s ease;
}

.navbar.scrolled {
  background: #fff;
  box-shadow: 0 7px 30px rgba(64, 84, 107, 0.3);
}

a.navbar-brand {
  color: rgba(0, 0, 0, 0.7) !important;
}

.nav-link {
  color: #5f5f5f;
  font-weight: 400;
}

.nav-link:hover {
  color: #0196fe;
  font-weight: 400;
}

.nav-toggle {
  color: rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 768px) {
  li.nav-item {
    text-align: left;
  }
}

@media screen and (max-width: 992px) {
  .navbar {
    background: #fbfbfb;
  }
}
