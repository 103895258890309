section#projects {
  padding: 30px 0 50px 0;
}

section#projects .projects-title {
  text-align: center;
  color: #494949;
  font-weight: 300;
}

section#projects .projects-subhead {
  text-align: center;
  color: #494949;
  font-weight: 300;
  margin-bottom: 30px;
}

@media screen and (max-width: 992px) {
  .card-columns {
    column-count: 1;
  }
}

@media screen and (min-width: 992px) {
  .card-columns {
    column-count: 3;
  }
}
