.project-likes-wrapper {
  display: flex;
  justify-content: center;
  width: 150px;
  justify-content: space-between;
}

.project-likes-wrapper button {
  border-color: #0074ff;
  width: 50px;
}

.project-likes-wrapper button.liked {
  background: #0074ff;
  color: #ffffff;
}

.project-likes-wrapper .btn-outline-primary:focus {
  outline: none !important;
  box-shadow: none;
}
